{
  "name": "sushimoon-angular",
  "version": "18.0.2",
  "type": "module",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --host 0.0.0.0",
    "build": "ng build && npm run sentry:sourcemaps && npm run sentry:sourcemaps",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "serve:ssr:sushimoon-angular": "node dist/sushimoon-angular/server/server.mjs",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org beta-life --project sushimoon-angular ./dist/sushimoon-angular && sentry-cli sourcemaps upload --org beta-life --project sushimoon-angular ./dist/sushimoon-angular"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.0.0",
    "@angular/common": "^18.0.0",
    "@angular/compiler": "^18.0.0",
    "@angular/core": "^18.0.0",
    "@angular/forms": "^18.0.0",
    "@angular/google-maps": "^18.2.3",
    "@angular/platform-browser": "^18.0.0",
    "@angular/platform-browser-dynamic": "^18.0.0",
    "@angular/platform-server": "^18.0.0",
    "@angular/router": "^18.0.0",
    "@angular/ssr": "^18.0.6",
    "@angular/youtube-player": "^18.2.3",
    "@ctrl/tinycolor": "^4.1.0",
    "@ngneat/query": "^3.0.0",
    "@ngx-pwa/local-storage": "^18.0.0",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@sentry/angular": "^9.5.0",
    "@sentry/cli": "^2.42.3",
    "@tailwindcss/postcss": "^4.0.14",
    "animate.css": "^4.1.1",
    "bootstrap-icons": "^1.11.3",
    "date-fns": "^3.6.0",
    "date-fns-tz": "^3.1.3",
    "express": "^4.18.2",
    "lodash": "^4.17.21",
    "md5": "^2.3.0",
    "ng-zorro-antd": "^18.1.1",
    "ngx-date-fns": "^11.0.0",
    "ngx-google-analytics": "^14.0.1",
    "ngx-owl-carousel-o": "^18.0.0",
    "ngx-quill": "^26.0.8",
    "qs": "^6.13.0",
    "rxjs": "~7.8.0",
    "ssr-window": "^4.0.2",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.0.6",
    "@angular/cli": "^18.0.6",
    "@angular/compiler-cli": "^18.0.0",
    "@tailwindcss/forms": "^0.5.9",
    "@tailwindcss/typography": "^0.5.15",
    "@types/express": "^4.17.17",
    "@types/jasmine": "~5.1.0",
    "@types/lodash": "^4.17.7",
    "@types/md5": "^2.3.5",
    "@types/node": "^18.18.0",
    "autoprefixer": "^10.4.20",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "^8.5.3",
    "tailwindcss": "^3.4.17",
    "typescript": "~5.4.2"
  }
}