export const environment = {
  title: "Sushi Moon | Traiteur Japonais Bruxelles",
  businessName: "Sushi Moon",
  appVersion: require("../../package.json").version,
  coreEndPoint: "https://mch.service.waimai.io",
  authEndPoint: "https://mch.service.waimai.io",
  googleMapApiKey: "AIzaSyDsPTA6r0yL_EYCK5dCMFrdU76wOM2k9Lc",
  merchantId: "mch_E4JPxZIWoc",
  gaId: "UA-84463413-1",
  production: true,
  withCredentials: true,
};
